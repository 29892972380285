/**
 * Transaction process configuration for a booking system.
 * Transitions and states are defined as key-value pairs.
 */

// Transitions defined as key-value pairs, each key is an identifier and each value is the transition name.
export const transitions = {
  INQUIRE: 'transition/inquire',
  REQUEST_BOOKING: 'transition/request-booking',
  REQUEST_BOOKING_AFTER_INQUIRY: 'transition/request-booking-after-inquiry',
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',
  EXPIRE: 'transition/expire',
  MARK_PENDING_PAYMENT: 'transition/mark-pending-payment',
  AUTO_PAYMENT: 'transition/auto-payment',
  INITIATE_MANUAL_PAYMENT: 'transition/initiate-manual-payment',
  CONFIRM_MANUAL_PAYMENT: 'transition/confirm-manual-payment',
  CANCEL_FROM_PENDING_CONFIRMATION: 'transition/cancel-from-pending-confirmation',
  COMPLETE: 'transition/complete',
  CANCEL: 'transition/cancel',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period'
};

// States defined as key-value pairs, each key is an identifier and each value is the state name.
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  PENDING_PAYMENT: 'pending-payment',
  PAID: 'paid',
  PENDING_CONFIRMATION: 'pending-confirmation',
  DELIVERED: 'delivered',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED: 'reviewed',
  CANCELLED: 'cancelled'
};

// Graph describing the state transitions
export const graph = {
  id: 'automatic-off-session-payment/release-1',
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_BOOKING]: states.PENDING,
      },
    },
    [states.CARD_SAVED]: {
      on: {
        [transitions.SAVE_CARD]: states.CARD_SAVED,
      },
    },
    [states.INQUIRY]: {
      //transitions: [transitions.REQUEST_BOOKING, transitions.REQUEST_BOOKING_AFTER_INQUIRY]
      on: {
        [transitions.REQUEST_BOOKING]: states.PENDING
      }
    },
    [states.PENDING]: {
      //transitions: [transitions.ACCEPT, transitions.DECLINE, transitions.EXPIRE]
      on: {
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.CANCELLED
      }
    },
    [states.ACCEPTED]: {
      //transitions: [transitions.MARK_PENDING_PAYMENT]
      on: {
        [transitions.MARK_PENDING_PAYMENT]: states.PENDING_PAYMENT
      }
    },
    [states.DECLINED]: {
      on: {
        [transitions.EXPIRE]: states.CANCELLED
      }
    },
    [states.PENDING_PAYMENT]: {
      transitions: [transitions.AUTO_PAYMENT, transitions.INITIATE_MANUAL_PAYMENT]
    },

    // [states.PAID]: {
    //   transitions: [transitions.COMPLETE, transitions.CANCEL, transitions.AUTO_PAYMENT]
    // },

    [states.PAID]: {
      on: {
        [transitions.AUTO_PAYMENT]: states.PAID
      }
    },

    [states.PENDING_CONFIRMATION]: {
      transitions: [transitions.CONFIRM_MANUAL_PAYMENT, transitions.CANCEL_FROM_PENDING_CONFIRMATION]
    },
    [states.DELIVERED]: {
      transitions: [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_1_BY_CUSTOMER]
    },
    [states.REVIEWED_BY_PROVIDER]: {
      transitions: [transitions.REVIEW_2_BY_CUSTOMER]
    },
    [states.REVIEWED_BY_CUSTOMER]: {
      transitions: [transitions.REVIEW_2_BY_PROVIDER]
    },
    [states.REVIEWED]: {
      transitions: [transitions.EXPIRE_REVIEW_PERIOD, transitions.EXPIRE_PROVIDER_REVIEW_PERIOD, transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]
    }
  }
};

/**
 * Helper functions to check specific attributes of transitions.
 */

// Check if a transition is the kind that should be rendered when showing transition history
export const isRelevantPastTransition = transition => {
  return [
    transitions.MARK_PENDING_PAYMENT,
    transitions.AUTO_PAYMENT,
    transitions.INITIATE_MANUAL_PAYMENT,
    transitions.CONFIRM_MANUAL_PAYMENT,
    transitions.ACCEPT,
    transitions.DECLINE,
    transitions.EXPIRE,
    transitions.COMPLETE,
    transitions.CANCEL,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER
  ].includes(transition);
};

// Check if a transition is a customer review
export const isCustomerReview = transition => {
  return [
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_2_BY_CUSTOMER
  ].includes(transition);
};

// Check if a transition is a provider review
export const isProviderReview = transition => {
  return [
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_PROVIDER
  ].includes(transition);
};

// Check if the transition is privileged and should be handled from a secure context
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_BOOKING,
    transitions.REQUEST_BOOKING_AFTER_INQUIRY
  ].includes(transition);
};

// Check when the transaction is considered completed
export const isCompleted = transition => {
  const completedTransitions = [
    transitions.COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD
  ];
  return completedTransitions.includes(transition);
};

// Check when the transaction is considered refunded
export const isRefunded = transition => {
  const refundedTransitions = [
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.DECLINE
  ];
  return refundedTransitions.includes(transition);
};

// An array listing states that require the provider's attention
export const statesNeedingProviderAttention = [states.PREAUTHORIZED];  // Adjust if the state names change

